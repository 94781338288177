import { getAPIClient } from "../helpers/APIHelpers";
import { dangerouslyDeserializeFunction } from "../helpers/serialization";
import { IDeveloperField } from "../interfaces";
import {
  UpdateUserFunctionsParams,
  UserFunctionsResponse,
} from "../interfaces/api";
import { AppThunk } from "../store/configureStore";
import {
  IndexEntry,
  selectKeyToIndexMap,
  selectMappedSpecs,
} from "../store/selectors";
import { FullDataWithMeta, HotChange, getRowMeta } from "./data_actions";
import i18next from "i18next";
import { RootState } from "../store/reducers";

export type UserFunctionFailure = { success: false; message: string };
export type UserFunctionSuccess = { success: true } & UserFunctionsResponse;
export type UserFunction = UserFunctionSuccess | UserFunctionFailure;
type TransformationChanges = {
  changes: HotChange[];
  removedRowIndexes: Set<number>;
  changedRowIndexes: Set<number>;
  changedColIndexes: Set<number>;
};

export type TransformDataSuccess = {
  success: true;
  data: FullDataWithMeta;
} & TransformationChanges;
export type TransformDataFailure = { success: false; message: string };
type TransformDataResult = TransformDataSuccess | TransformDataFailure;

type FullDataForUserFunctionRow = {
  rowId: string;
  data: Record<string, unknown>;
};

type FieldForUserFunction = IDeveloperField & { numValues: number };

export type IFieldsForUserFunction = [
  fieldKey: string,
  field: FieldForUserFunction
][];

const selectColumnMappingForUserFunction = (
  state: RootState
): IFieldsForUserFunction => {
  const entryMap = new Map<string, FieldForUserFunction>();
  const mappedFields = selectMappedSpecs(state);

  for (const field of mappedFields.values()) {
    if (entryMap.has(field.key)) {
      entryMap.get(field.key)!.numValues++;
    } else {
      entryMap.set(field.key, { ...field, numValues: 1 });
    }
  }

  return [...entryMap];
};

export const updateUserFunction = (
  id: string,
  status: UpdateUserFunctionsParams["status"],
  errorType?: string
): AppThunk<Promise<UserFunction>> => {
  return async (_, getState) => {
    const api = getAPIClient(getState());

    const response = await api.updateUserFunction(id, {
      status,
      errorType: errorType ?? null,
    });

    const success =
      response.status !== "error" && response.response_object.code !== null;

    return success
      ? { success, ...response }
      : {
          success,
          message:
            response.response_object.message ||
            i18next.t("userFunctionModal.error"),
        };
  };
};

export const getUserFunction = (
  userPrompt: string
): AppThunk<Promise<UserFunction>> => {
  return async (_, getState) => {
    if (userPrompt.length < 5) {
      return { success: false, message: "Please provide a longer prompt." };
    }

    const state = getState();
    const api = getAPIClient(state);
    const serializedColumnMapping = selectColumnMappingForUserFunction(state);

    const response = await api.getUserFunction({
      column_mapping: serializedColumnMapping,
      user_prompt: userPrompt,
    });

    const success =
      response.status !== "error" && response.response_object.code !== null;

    return success
      ? { success, ...response }
      : {
          success,
          message:
            response.response_object.message ||
            i18next.t("userFunctionModal.error"),
        };
  };
};

const formatDataForUserFunction = (
  fullData: FullDataWithMeta
): AppThunk<FullDataForUserFunctionRow[]> => {
  return (_, getState) => {
    const state = getState();
    const mappedFields = selectMappedSpecs(state);

    const activeColumns = new Map<number, string>();
    const keyToIndexMap = selectKeyToIndexMap(state);

    mappedFields.forEach((field, colIndex) => {
      if (!field.hidden) {
        activeColumns.set(colIndex, field.key);
      }
    });

    const resultData: { rowId: string; data: Record<string, unknown> }[] =
      fullData.map((row) => {
        const newRowData: Record<string, unknown> = {};

        activeColumns.forEach((columnName, index) => {
          if (columnName in newRowData) return;

          const indexEntry = keyToIndexMap.get(columnName)!;

          if (indexEntry.manyToOne) {
            newRowData[columnName] = indexEntry.indexes.map(
              (colIdx) => row[colIdx]
            );
          } else {
            newRowData[columnName] = row[index];
          }
        });

        return { rowId: getRowMeta(row).rowId, data: newRowData };
      });

    return resultData;
  };
};

const toFlatSparseRow = (
  record: Record<string, unknown>,
  keyToIndexMap: Map<string, IndexEntry>
): unknown[] => {
  const flatRow: unknown[] = [];
  for (const key in record) {
    const indexEntry = keyToIndexMap.get(key);
    if (!indexEntry) continue;

    if (indexEntry.manyToOne) {
      indexEntry.indexes.forEach((rowIndex, i) => {
        flatRow[rowIndex] = (record[key] as unknown[])[i];
      });
    } else {
      flatRow[indexEntry.index] = record[key];
    }
  }
  return flatRow;
};

const getChangesForRow = (
  originalRow: FullDataForUserFunctionRow,
  transformedDataMap: Map<string, Record<string, unknown>>,
  keyToIndexMap: Map<string, IndexEntry>
) => {
  if (!transformedDataMap.has(originalRow.rowId)) {
    return { removed: true, changes: [] };
  }

  const transformedRow = transformedDataMap.get(originalRow.rowId)!;

  const originalArray = toFlatSparseRow(originalRow.data, keyToIndexMap);
  const transformedArray = toFlatSparseRow(transformedRow, keyToIndexMap);

  const changes: [colIndex: number, oldValue: unknown, newValue: unknown][] =
    [];

  originalArray.forEach((originalValue, colIndex) => {
    const transformedValue = transformedArray[colIndex];
    if (transformedValue !== originalValue) {
      changes.push([colIndex, originalValue, transformedValue]);
    }
  });

  return { removed: false, changes };
};

export const getChangesFromUserFunction = (
  originalData: FullDataForUserFunctionRow[],
  transformedData: FullDataForUserFunctionRow[]
): AppThunk<TransformationChanges> => {
  return (_, getState) => {
    const keyToIndexMap = selectKeyToIndexMap(getState());

    const transformedDataMap = new Map(
      transformedData.map(({ rowId, data }) => [rowId, data])
    );

    const changes = originalData.map((originalRow) =>
      getChangesForRow(originalRow, transformedDataMap, keyToIndexMap)
    );

    const transformationChanges: TransformationChanges = {
      changes: [],
      removedRowIndexes: new Set(),
      changedRowIndexes: new Set(),
      changedColIndexes: new Set(),
    };

    changes.forEach((changeObj, originalRowIndex) => {
      if (changeObj.removed) {
        transformationChanges.removedRowIndexes.add(originalRowIndex);
      }

      changeObj.changes.forEach(([colIndex, oldValue, newValue]) => {
        transformationChanges.changes.push([
          originalRowIndex,
          colIndex,
          oldValue,
          newValue as string | null,
        ]);
        transformationChanges.changedRowIndexes.add(originalRowIndex);
        transformationChanges.changedColIndexes.add(colIndex);
      });
    });

    return transformationChanges;
  };
};

export const applyTransformationsToData = (
  originalData: FullDataWithMeta,
  hotChanges: HotChange[]
): FullDataWithMeta => {
  const modifiedFullData = [...originalData];

  for (const [rowIndex, colIndex, , newValue] of hotChanges) {
    modifiedFullData[rowIndex][colIndex] = newValue;
  }

  return modifiedFullData;
};

export const transformDataWithUserFunction = (
  fullData: FullDataWithMeta,
  functionString: string,
  id: string,
  isRetry?: boolean
): AppThunk<Promise<TransformDataResult>> => {
  return async (dispatch) => {
    const originalDataFormatted = dispatch(formatDataForUserFunction(fullData));
    try {
      const transformationFunction: (
        data: FullDataForUserFunctionRow[]
      ) => FullDataForUserFunctionRow[] =
        dangerouslyDeserializeFunction(functionString);

      const transformedData: FullDataForUserFunctionRow[] =
        transformationFunction([...originalDataFormatted]);

      const transformationChanges = dispatch(
        getChangesFromUserFunction(originalDataFormatted, transformedData)
      );

      // Apply all value changes, but do not remove rows, we do that separately
      const dataAfterTransformation = applyTransformationsToData(
        fullData,
        transformationChanges.changes
      );

      return {
        success: true,
        data: dataAfterTransformation,
        ...transformationChanges,
      };
    } catch (e) {
      if (!isRetry) {
        const repromptFunction = await dispatch(
          updateUserFunction(id, "error", e as string)
        );
        if (repromptFunction.success) {
          return await dispatch(
            transformDataWithUserFunction(
              fullData,
              repromptFunction.id,
              repromptFunction.response_object.code,
              true
            )
          );
        } else {
          return {
            success: false,
            message:
              repromptFunction.message ?? i18next.t("userFunctionModal.error"),
          };
        }
      }
      return { success: false, message: i18next.t("userFunctionModal.error") };
    }
  };
};
