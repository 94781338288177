import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import * as Sentry from "@sentry/browser";
import type { AnyAction, ActionCreator } from "redux";
import { connectToParent, Connection } from "penpal";

import { IParentConnectionMethods } from "../interfaces";
import { useAppDispatch } from "../store/hooks";
import { AppThunk, AppDispatch } from "../store/configureStore";
import { addError } from "../store/reducers/errors";
import connectionMethodThunks from "../thunks/connection_methods";

export type ParentConnection = Connection<IParentConnectionMethods>;

export const ParentConnectionContext = createContext<ParentConnection | null>(
  null
);

export const ParentConnectionProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const [connection, setConnection] = useState<ParentConnection | null>(null);

  const connectionMethods = useMemo(
    () => wrapActionCreators(connectionMethodThunks, dispatch),
    [dispatch]
  );

  useEffect(() => {
    if (window === window.top) {
      dispatch(
        addError({
          type: "developer",
          code: "E_SDK_CONNECTION_ERROR",
          message: "Dromo must be loaded using the Dromo SDK!",
        })
      );
      return;
    }

    const newConnection = connectToParent<IParentConnectionMethods>({
      methods: connectionMethods,
    });
    setConnection(newConnection);
  }, [connectionMethods, dispatch, setConnection]);

  return (
    <ParentConnectionContext.Provider value={connection}>
      {children}
    </ParentConnectionContext.Provider>
  );
};

export const useParentConnectionContext = () =>
  useContext(ParentConnectionContext);

// Penpal will swallow errors in methods called by the parent and raise them on the parent.
// We want to catch them here on the child-side
const wrapAction = (action: AnyAction): AppThunk => {
  return async (dispatch) => {
    try {
      return dispatch(action);
    } catch (error) {
      console.error(error);
      const sentryEventId = Sentry.captureException(error);

      dispatch(
        addError({
          type: "dromo",
          code: "E_UNCAUGHT_EXCEPTION",
          messageKey: "alert.genericError",
          extra: { sentryEventId },
        })
      );
    }
  };
};

const wrapActionCreators = (
  actions: Record<string, ActionCreator<any>>,
  dispatch: AppDispatch
): Record<string, AppThunk> => {
  const entries = [];
  for (const [name, fn] of Object.entries(actions)) {
    entries.push([name, (...args: any[]) => dispatch(wrapAction(fn(...args)))]);
  }

  return Object.fromEntries(entries);
};
